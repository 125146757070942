import $ from 'jquery';
import dateParser from '../i18n/dateParser';
import dateFormatter from '../i18n/dateFormatter';
import { activeFilterValues as activeFilterValuesGetter } from '../store/modules/dynamic/overview/getters';

$.fn.dataTable.ext.search.push(
    function dateFilter(settings, data, dataIndex) {
        const dateFilters = getRelevantFilters(settings);

        // Does this table have a date filter?
        if (!dateFilters.length) {
            return true;
        }

        const activeFilterValues = activeFilterValuesGetter(settings.oInit.oCustomState);
        const activeDateFilters = dateFilters.filter(f => activeFilterValues[f.attribute]);

        // Get the DataTable instance so we can determine the indexes of the needed columns
        const dataTable = $(settings.nTable).DataTable();

        // Check all active date filters
        for (const filter of activeDateFilters) {
            const columnIndex = dataTable.column(filter.attribute + ':name').index();

            // Parse the dates
            const dateOne = parseDate(data[columnIndex]);
            const dateTwo = parseDate(activeFilterValues[filter.attribute]);

            // Compare the dates
            if (compareDates(dateOne, filter.operator, dateTwo) === false) {
                return false;
            }
        }

        return true;
    }
);

function getRelevantFilters(settings, type = 'date') {
    if (!settings.oInit.oCustomSettings || !settings.oInit.oCustomState) {
        return [];
    }

    return settings.oInit.oCustomSettings.filters.filter(f => f.type === type);
}

function parseDate(date) {
    return dateParser(date.substring(0, 10) + 'T00:00:00');
}

function compareDates(dateOne, operator, dateTwo) {
    switch (operator) {
        case '<':
            return dateOne < dateTwo;
        case '<=':
            return dateOne <= dateTwo;
        case '=':
        case '==':
        case '===':
            return dateOne.getTime() === dateTwo.getTime();
        case '<>':
        case '!=':
        case '!==':
            return dateOne.getTime() !== dateTwo.getTime();
        case '>=':
            return dateOne >= dateTwo;
        case '>':
            return dateOne > dateTwo;
        default:
            throw new Error('Date filter operator ' + operator + ' not supported!');
    }
}

export function appendFilterData(data, settings) {
    const dateFilters = getRelevantFilters(settings);

    // Does this table have a date filter?
    if (!dateFilters.length) {
        return true;
    }

    const activeFilterValues = activeFilterValuesGetter(settings.oInit.oCustomState);
    const activeDateFilters = dateFilters.filter(f => activeFilterValues[f.attribute]);

    // Add the values to the data
    for (const filter of activeDateFilters) {
        for (const column of data.columns) {
            if (column.data !== filter.attribute) {
                continue;
            }

            column.search.value = dateFormatter(parseDate(activeFilterValues[filter.attribute]));
        }
    }
}
