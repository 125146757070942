<script>
    import S from 'string';
    import OverviewTable from '../Overview/Table';

    export default {
        extends: OverviewTable,

        methods: {
            rowGroupStartRender(rows, group) {
                const totalAmount = rows.data().toArray().reduce((acc, row) => acc + row.amount_incl_vat, 0);
                const title = `<strong>${S(group).escapeHTML().toString()}</strong> | ${this.$tc('financial.values.number-of-appointments', rows.count(), { number: rows.count() })} | ${this.$c(totalAmount)}`;

                return OverviewTable.methods.rowGroupStartRender.call(this, rows, title);
            },
        },
    };
</script>
