import merge from 'deepmerge';
import overviewModule from '../../store/modules/dynamic/overview';

let identifier = 0;
const mergeOptions = {
    arrayMerge: (store, saved) => saved,
    clone: false,
};

const addedModules = new Set();

/**
 * N.B. Merge package and options are copied from vuex-persistedstate (https://github.com/robinvdvleuten/vuex-persistedstate),
 * please keep in sync when updating!
 */
// @vue/component
export default {
    created() {
        const namespace = this.storeNamespace || [];

        if (addedModules.has(namespace.join('/'))) {
            return;
        }

        const defaultState = overviewModule.state();

        if (this.showOnlyActiveShouldSearchNegative) {
            defaultState.showOnlyActive = false;
        }

        const overviewState = merge(
            defaultState,
            namespace.reduce((state, namespace) => state ? state[namespace] : undefined, this.$store.state) || {},
            mergeOptions
        );
        overviewState.uniqueIdentifier = identifier++;

        if (this.initialSearchQuery) {
            overviewState.searchQuery = this.initialSearchQuery;
            overviewState.fieldValueQuery = null;
            // DataTables checks for a timestamp before it accepts a saved state
            overviewState.dataTablesState.time = (new Date()).getTime();
            overviewState.dataTablesState.search = overviewState.dataTablesState.search || {};
            overviewState.dataTablesState.search.search = this.initialSearchQuery;
        }

        const substate = namespace.reduceRight((acc, namespace) => {
            return { [namespace]: acc };
        }, overviewState);

        this.$store.replaceState(merge(this.$store.state, substate, mergeOptions));
        this.$store.registerModule(namespace, overviewModule, { preserveState: true });
        addedModules.add(namespace.join('/'));
    },
};
