<script>
    import HandlesActions from '../../mixins/Overview/HandlesActions';
    import updateQueryStringParameter from '../../services/updateQueryStringParameter';
    import OverviewTree from '../OverviewTree.vue';

    export default {
        extends: OverviewTree,

        props: {
            module: {
                type: String,
                required: true,
            },

            section: {
                type: String,
                required: true,
            },

            formField: {
                type: String,
                required: true,
            },
        },

        methods: {
            /**
             * @param {string} url
             *
             * @returns {string}
             */
            parseUrl(url) {
                url = HandlesActions.methods.parseUrl.bind(this)(url);

                url = updateQueryStringParameter(url, 'module', this.module);
                url = updateQueryStringParameter(url, 'section', this.section);
                url = updateQueryStringParameter(url, 'form_field', this.formField);

                return url;
            },
        },
    };
</script>
