<template>
    <div :id="'overview_' + uniqueIdentifier" class="overview">
        <div class="overview__table">
            <div class="overview-tree">
                <div class="overview-tree__header">
                    <div v-if="config.checkable" class="form-checkbox overview-tree__checkbox">
                        <label>
                            <input
                                :id="'frm_table_select_all_' + uniqueIdentifier"
                                class="form-checkbox__input group-checkable"
                                type="checkbox"
                                value=""
                                @click="toggleAll"
                            >
                            <span class="form-checkbox__label form-checkbox__label--no-content" />
                        </label>
                    </div>
                    <h3 class="overview-tree__header-text">
                        {{ config.title }}
                    </h3>
                </div>
                <div v-if="loading" class="overview-tree__loader">
                    <div class="spinner">
                        <div class="spinner__dot" />
                        <div class="spinner__dot" />
                        <div class="spinner__dot" />
                    </div>
                </div>
                <div v-else-if="empty" class="overview-tree__empty">
                    {{ $t('overview.clean-slate') }}
                </div>
                <div v-show="!loading" class="overview-tree__scrollBody">
                    <div :id="'overview_tree_' + uniqueIdentifier" ref="tree" />
                </div>
            </div>
        </div>

        <frm-overview-actions
            :id="'overview-actions_' + uniqueIdentifier"
            ref="actions"
            :config="config"
            :handle-action="handleAction"
        />
    </div>
</template>

<script>
    import $ from 'jquery';
    import 'jqtree/lib/tree.jquery';
    import { mapMutations, mapState } from '../store/helpers';
    import CreatesOverviewState from '../mixins/Overview/CreatesState';
    import DefaultOverviewMixin from '../mixins/Overview/Default';
    import HandlesActions from '../mixins/Overview/HandlesActions';

    import OverviewActions from './Overview/Actions.vue';
    import buildContextMenuItemsFromActions from '../services/buildContextMenuItemsFromActions';
    import debounce from '../services/debounce';
    import elementIsInViewport from '../services/elementIsInViewport';
    import iconRenderer from '../services/iconRenderer';
    import ModalManager from '../services/modalManager';
    import axios from 'axios';

    const AUTO_OPEN = false;

    function visibleNodes(nodes, $tree) {
        return nodes.filter(elem => {
            let node = $tree.tree('getNodeByHtmlElement', $(elem)).parent;

            while (node !== null && node.getLevel() > 0) {
                if (node.children.length > 0) {
                    node.is_open = node.is_open === undefined ? AUTO_OPEN : node.is_open;

                    if (node.is_open === false) {
                        return false;
                    }
                }

                node = node.parent;
            }

            return true;
        });
    }

    export default {
        mixins: [CreatesOverviewState, DefaultOverviewMixin, HandlesActions],

        components: {
            'frm-overview-actions': OverviewActions,
        },

        props: {
            config: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                loading: true,
                empty: false,
                showOnlyActiveShouldSearchNegative: false,
            };
        },

        computed: {
            ...mapState([
                'activeFilters', // N.B. Needed for HandlesActions
                'readonlyIds', // N.B. Needed for HandlesActions
                'selectedIds', // N.B. Needed for HandlesActions
                'selectedRowsData', // N.B. Needed for HandlesActions
                'uniqueIdentifier',
            ]),

            dataTablesState: {
                get() {
                    return this.getFromState('dataTablesState');
                },
                set(value) {
                    this.setDataTablesState({ ...value, selected_node: [] });
                },
            },
        },

        mounted() {
            this.$options.$tree = $(this.$refs.tree);
            this.$options.$tree.tree({
                dataUrl: this.config.url,
                dataFilter: data => data.data,
                autoOpen: AUTO_OPEN,
                dragAndDrop: this.config.sortable,
                onCanMoveTo: (movedNode, targetNode, position) => {
                    // you can only move a node within its parent
                    return ((position === 'inside' && movedNode.parent.id === targetNode.id) || (position !== 'inside' && movedNode.parent.id === targetNode.parent.id));
                },
                onCreateLi: (node, $li) => {
                    let icon = '';
                    if (node.type !== 'separator') {
                        icon = iconRenderer(node.type, 'overview-tree__icon');
                    }

                    if (this.config.checkable) {
                        const $element = $li.find('.jqtree-element:first');
                        $element.addClass('jqtree-element--checkbox');

                        if (node.readonly) {
                            $element.prepend('<div class="overview-tree__checkbox overview-tree__checkbox--empty overview-tree__checkbox--row"></div>');
                        } else {
                            $element.prepend(`<div class="overview-tree__checkbox overview-tree__checkbox--row">
                                    <div class="form-checkbox">
                                        <input id="overview-active-${node.type}-${node.id}" name="overview-active[]" type="checkbox" value="${node.id}" class="form-checkbox__input">
                                        <label for="overview-active-${node.type}-${node.id}" class="form-checkbox__label form-checkbox__label--no-content"></label>
                                    </div>
                                </div>`);
                        }
                    }

                    $li.addClass('overview-tree__row type-' + node.type)
                        .find('.jqtree-title')
                        .prepend(icon);

                    if (this.config.sortable) {
                        $li.find('.jqtree-element:first')
                            .append(`<a href="#" class="overview-tree__row-handler" data-is-handler="1">${iconRenderer('arrow-up-down', 'overview-tree__row-handler-icon')}</a>`);
                    }
                },
                onIsMoveHandle: ($element) => {
                    return $element.attr('data-is-handler');
                },
            }).on('tree.init', (event) => {
                const state = { ...this.dataTablesState };

                if (window.location.hash.substr(0, 5) === '#row_') {
                    state.selected_node = window.location.hash.substr(5).split(',');
                }

                this.$options.$tree.tree('setState', state);

                if (state.selected_node && state.selected_node.length) {
                    const lastNode = this.$options.$tree.tree('getNodeById', state.selected_node[state.selected_node.length - 1]);

                    if (lastNode) {
                        requestAnimationFrame(() => {
                            // if the element is not in the viewport, scroll to the element
                            if (!elementIsInViewport(lastNode.element)) {
                                lastNode.element.scrollIntoView({ block: 'center' });
                            }
                        });
                    }
                }
            }).on('tree.loading_data', (event) => {
                this.loading = event.isLoading;
            }).on('tree.load_data', (event) => {
                this.empty = event.tree_data.length < 1;

                // The load_data event is fired too early, but there is no alternative, so we use a setTimeout here.
                setTimeout(() => {
                    this.applyEvenElementClass();
                }, 0);
            }).on('tree.move', (event) => {
                event.preventDefault();
                event.move_info.do_move();

                // not needed for now
                // let parent = null;
                // if (event.move_info.moved_node.parent) {
                //     parent = event.move_info.moved_node.parent.id;
                // }

                // index berekenen
                let index = 0;
                let prevNode = event.move_info.moved_node;

                while (prevNode !== null) {
                    prevNode = prevNode.getPreviousSibling();
                    if (prevNode) {
                        index++;
                    }
                }

                this.applyEvenElementClass();

                axios.post(this.config.sortableAction, {
                    id: event.move_info.moved_node.id,
                    position: index + 1,
                }).then(response => {
                    // do something with the response
                }).catch((response) => {
                    // do something with the error
                });
            }).on('tree.click', (event) => {
                const $checkbox = this.$options.$tree.find('input[name="overview-active[]"][value="' + event.node.id + '"]').closest('.form-checkbox');

                // Disable node selection if the user clicked the checkbox
                if ($checkbox.length > 0 && $checkbox.get(0).contains(event.click_event.target)) {
                    event.preventDefault();
                    if (event.stopPropagation) event.stopPropagation();

                    return false;
                }

                // Unselect all nodes if we have selected more than one
                const nodes = this.$options.$tree.tree('getSelectedNodes');
                if (nodes.length > 1) {
                    nodes.forEach(node => {
                        this.$options.$tree.tree('removeFromSelection', node);
                    });
                }
            }).on('click', '.overview-tree__checkbox .form-checkbox__input', (event) => {
                const node = this.$options.$tree.tree('getNodeById', event.target.value);

                if (this.$options.$tree.tree('isNodeSelected', node)) {
                    this.$options.$tree.tree('removeFromSelection', node);
                } else {
                    this.$options.$tree.tree('addToSelection', node);
                }
                this.setSelectedRows(this.$options.$tree.tree('getSelectedNodes').map(node => node.id));
            }).on('tree.select', (event) => {
                // Uncheck all checkboxes
                if (this.config.checkable) {
                    this.$options.$tree.find('input[name="overview-active[]"]').prop('checked', false);
                }

                if (event.node === null) {
                    this.setSelectedRows([]);
                } else {
                    if (this.config.checkable) {
                        this.$options.$tree.find('input[name="overview-active[]"][value="' + event.node.id + '"]').prop('checked', true);
                    }

                    this.setSelectedRows([event.node.id]);
                }
            }).on('tree.open tree.close', (event) => {
                this.applyEvenElementClass();
            }).on('tree.open tree.close', (event) => {
                this.dataTablesState = this.$options.$tree.tree('getState');
            }).on('tree.dblclick', (event) => {
                this.$options.$tree.tree('selectNode', this.$options.$tree.tree('getNodeById', event.node.id));
                this.setSelectedRows([event.node.id]);
                this.handleAction(this.editAction, event.originalEvent);
            });

            $(document).on('keydown', (event) => {
                if (event.which === 13) {
                    this.handleAction(this.editAction, event.originalEvent);
                    event.stopPropagation();
                    event.preventDefault();
                }
            });

            window.addEventListener('resize', debounce(() => {
                this.handleResize();
            }, 25));
            ModalManager.$on(['beforeEnter', 'afterLeave'], () => {
                requestAnimationFrame(() => {
                    this.handleResize();
                });
            });
            this.handleResize();

            const contextMenuItems = this.buildContextMenuItems(this.config.contextMenuItems);

            const self = this;
            this.$options.$tree.contextMenu({
                // define which elements trigger this menu
                selector: '.overview-tree__row',
                className: 'contextmenu_' + this.uniqueIdentifier,
                zIndex: 10,
                events: {
                    show() {
                        const clickedNode = $(self.$refs.tree).tree('getNodeByHtmlElement', $(this).closest('li.overview-tree__row'));
                        if (clickedNode) {
                            $(self.$refs.tree).tree('selectNode', clickedNode);
                        }
                    },
                },
                items: contextMenuItems,
            });
        },

        methods: {
            ...mapMutations([
                'setDataTablesState',
                'setSelectedIds',
                'setSelectedRowsData',
                'setReadonlyIds',
            ]),

            /**
             * @param {String[]} ids
             */
            setSelectedRows(ids) {
                this.setSelectedIds(ids);

                const readonlyIds = [];
                const rowData = ids.map(id => {
                    const node = this.$options.$tree.tree('getNodeById', id);
                    const { children, ...data } = node.getData(true)[0];

                    if (data.readonly === true) {
                        readonlyIds.push(id);
                    }

                    return data;
                });
                this.setSelectedRowsData(rowData);
                this.setReadonlyIds(readonlyIds);
            },

            buildContextMenuItems(actions) {
                return buildContextMenuItemsFromActions(actions, (action, element, key, opt) => {
                    this.handleAction(action);
                }, (action, element, key, opt) => {
                    return this.isActionDisabled(action, element, key, opt);
                });
            },

            isActionDisabled(action) {
                const rowsData = this.$options.$tree.tree('getSelectedNodes').map(node => node.getData(true)[0]);

                return this.actionIsRestricted(action, rowsData);
            },

            getScrollY() {
                // TODO: Calculate this
                let subtract = this.config.hideHeaders ? 55 : 105;

                const actions = $(`#overview-actions_${this.uniqueIdentifier}`);
                if (actions.length) {
                    subtract += actions.outerHeight();
                }

                const $stickyButtonGroup = $('.form-button-group--sticky');
                if ($stickyButtonGroup.length) {
                    subtract += $stickyButtonGroup.outerHeight();
                }

                return ($(window).height() - $(this.$el).offset().top) - subtract;
            },

            updateScrollY() {
                $(this.$el).find('.overview-tree__scrollBody').css('max-height', Math.max(180, this.getScrollY()));
            },

            handleResize() {
                if ($(this.$el).is(':visible')) {
                    this.updateScrollY();
                }
            },

            applyEvenElementClass() {
                let nodes = this.$refs.tree.getElementsByClassName('jqtree-element');
                nodes = visibleNodes([].slice.call(nodes), $(this.$refs.tree));

                for (let i = 0; i < nodes.length; i++) {
                    if (i % 2 !== 0) {
                        nodes[i].classList.add('jqtree-element--even');
                    } else {
                        nodes[i].classList.remove('jqtree-element--even');
                    }
                }
            },

            toggleAll(event) {
                this.toggle(event.target.checked);
            },

            toggle(on) {
                if (on) {
                    this.$options.$tree.find('input[name="overview-active[]"]').prop('checked', true);
                    const selectedIds = [];
                    this.$options.$tree.find('input[name="overview-active[]"]').each((index, checkbox) => {
                        this.$options.$tree.tree('addToSelection', this.$options.$tree.tree('getNodeById', checkbox.value));
                        selectedIds.push(checkbox.value);
                    });
                    this.setSelectedRows(selectedIds);
                } else {
                    this.$options.$tree.find('input[name="overview-active[]"]').prop('checked', false);
                    this.$options.$tree.tree('getSelectedNodes').forEach(node => {
                        this.$options.$tree.tree('removeFromSelection', node);
                    });
                    this.setSelectedRows([]);
                }
            },

            refresh(callback = null) {
                if (this.$options.$tree) {
                    this.$options.$tree.tree('reload', (...args) => {
                        this.setSelectedRows(this.$options.$tree.tree('getSelectedNodes').map(node => node.id));

                        if (callback !== null) {
                            callback(...args);
                        }
                    });
                }
            },

            selectRow(id) {
                if (this.config.checkable) {
                    this.$options.$tree.find('input[name="overview-active[]"]').prop('checked', false);
                }
                this.$options.$tree.tree('getSelectedNodes').forEach(node => {
                    this.$options.$tree.tree('removeFromSelection', node);
                });
                this.$options.$tree.tree('selectNode', this.$options.$tree.tree('getNodeById', id));
                this.setSelectedRows([id]);
            },
        },

        $tree: null,
    };
</script>
