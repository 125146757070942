import $ from 'jquery';
import dateParser from '../i18n/dateParser';

$.fn.dataTable.ext.search.push(
    function showOnlyCurrentFilter(settings, data, dataIndex) {
        // Does this table have a showOnlyCurrent filter?
        if (!settings.oInit.oCustomSettings || !settings.oInit.oCustomSettings.showCurrentFilter) {
            return true;
        }

        // Is the filter enabled?
        if (!settings.oInit.oCustomState || !settings.oInit.oCustomState.showOnlyCurrent) {
            return true;
        }

        // Get the DataTable instance so we can determine the indexes of the needed columns
        const dataTable = $(settings.nTable).DataTable();
        const startdateColumnIndex = dataTable.column('startdate:name').index();
        const enddateColumnIndex = dataTable.column('enddate:name').index();

        // Parse the dates
        const now = new Date();
        const startdate = dateParser(data[startdateColumnIndex].length === 10 ? data[startdateColumnIndex] + 'T00:00:00' : data[startdateColumnIndex]);
        const enddate = dateParser(data[enddateColumnIndex].length === 10 ? data[enddateColumnIndex] + 'T23:59:59' : data[enddateColumnIndex]);

        // Compare the dates
        return startdate <= now && (enddate >= now || data[enddateColumnIndex] === '');
    }
);
