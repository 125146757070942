export const activeFilterValues = (state) => {
    return state.activeFilters.reduce((acc, filter) => {
        acc[filter.attribute] = filter.value;

        return acc;
    }, {});
};

export const rowReorderDisabled = (state) => {
    return !!(
        (state.dataTablesState.search && state.dataTablesState.search.search) ||
        (state.showFilters && state.showOnlyActive) ||
        (state.showFilters && state.showOnlyCurrent) ||
        state.activeFilters.length > 0
    );
};
