<template>
    <div class="overview__actions">
        <div class="overview-actions-dropdown">
            <div class="form-select overview-actions-dropdown__select" :class="{'form-select--disabled': disabled}">
                <select v-model="selectedAction" class="form-select__field">
                    <option :value="null" />
                    <template v-for="(action, index) in actions">
                        <option v-if="action.type === 'break'" :key="index" :value="null" disabled>
                            ───────────────
                        </option>
                        <option v-else :key="index" :disabled="disabled" :value="action">
                            {{ action.label }}
                        </option>
                    </template>
                </select>
            </div>
            <button :disabled="buttonDisabled" class="button" type="button" @click="handleAction(selectedAction, $event)">
                {{ $t('overview.execute') }}
            </button>
        </div>
    </div>
</template>

<script>
    import DefaultOverviewMixin from '../../mixins/Overview/Default';
    import HandlesActionRestrictions from '../../mixins/Overview/HandlesActionRestrictions';
    import { mapState } from '../../store/helpers';

    export default {
        mixins: [DefaultOverviewMixin, HandlesActionRestrictions],

        props: {
            config: {
                type: Object,
                required: true,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            handleAction: {
                type: Function,
                required: true,
            },
        },

        data() {
            return {
                selectedAction: null,
            };
        },

        computed: {
            ...mapState([
                'selectedRowsData',
            ]),

            actions() {
                if (!this.selectedRowsData.length || this.selectedRowsData.some(row => !row.dropdown_menu_items || !row.dropdown_menu_items.data)) {
                    return [];
                }

                // Only return actions available for every selected row, based on the action name.
                let actions = this.selectedRowsData[0].dropdown_menu_items.data;
                this.selectedRowsData.slice(1).forEach(row => {
                    actions = actions.filter(action => row.dropdown_menu_items.data.find(i => i.name === action.name));
                });

                return actions;
            },

            buttonDisabled() {
                return this.disabled || !this.actions.length || !this.selectedAction || this.actionIsRestricted(this.selectedAction, this.selectedRowsData);
            },
        },
    };
</script>
