<template>
    <div class="field-value-filter">
        <div class="field-value-filter__field">
            <frm-input-select
                :id="id"
                v-model="field"
                :disabled="disabled"
                :options="fieldOptions"
            />
        </div>
        <div class="field-value-filter__value">
            <div class="form-icon-field">
                <input
                    v-if="currentOption === null || currentOption.type === 'text'"
                    v-model="query"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    class="form-input form-icon-field__field"
                >
                <frm-input-date-picker
                    v-else-if="currentOption.type === 'date'"
                    v-model="query"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    class="form-datepicker--full-width"
                />
                <button type="button" class="form-icon-field__button" @click="query = ''">
                    <frm-icon :name="query ? 'cross-2' : 'magnifying-glass'" class="form-icon-field__icon" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        id: {
            type: String,
            required: true,
        },

        options: {
            type: Array,
            required: true,
        },

        value: {
            type: Object,
            default: null,
        },
    },

    computed: {
        field: {
            get() {
                return this.value ? this.value.field : null;
            },
            set(field) {
                this.$emit('input', { field, value: null });
            },
        },

        query: {
            get() {
                return this.value ? this.value.value : null;
            },
            set(value) {
                this.$emit('input', { field: this.field, value });
            },
        },

        fieldOptions() {
            return this.options.map(option => {
                return {
                    value: option.attribute,
                    label: option.label,
                };
            });
        },

        currentOption() {
            if (!this.field) {
                return null;
            }

            return this.options.find(option => option.attribute === this.field);
        },

        placeholder() {
            if (this.currentOption === null) {
                return this.$t('overview.search-value-placeholder-initial');
            }

            let fieldLabel = this.currentOption.label;
            if (fieldLabel !== fieldLabel.toUpperCase()) {
                fieldLabel = fieldLabel.toLowerCase();
            }

            return this.$t('overview.search-value-placeholder', { field: fieldLabel });
        },
    },
};
</script>
