import * as getters from './getters';
import * as mutations from './mutations';
import state from './state';

export default {
    namespaced: true,
    getters,
    mutations,
    state() {
        return state;
    },
};
