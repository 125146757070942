<template>
    <button
        :class="classObject"
        :disabled="disabledComputed"
        class="button button--grouped"
        type="button"
        @click="loading = true; handleAction(action, $event)"
    >
        <span class="button__icon-group">
            <frm-icon v-if="!loading && action.icon" :name="action.icon" class="button__icon" />
            <spinner v-if="loading" white class="button__spinner" />
            <span>{{ action.label }}</span>
        </span>
    </button>
</template>

<script>
    import { mapState } from '../../store/helpers';
    import DefaultOverviewMixin from '../../mixins/Overview/Default';
    import HandlesActionRestrictions from '../../mixins/Overview/HandlesActionRestrictions';
    import Spinner from '../Spinner';

    export default {
        components: { Spinner },
        mixins: [DefaultOverviewMixin, HandlesActionRestrictions],

        props: {
            action: {
                type: Object,
                required: true,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            config: {
                type: Object,
                required: true,
            },

            handleAction: {
                type: Function,
                required: true,
            },
        },

        computed: {
            ...mapState([
                'readonlyIds',
                'selectedRowsData',
            ]),

            classObject() {
                return [
                    'js-btn-' + this.action.name,
                    'button--small',
                ];
            },

            disabledComputed() {
                return this.disabled || this.actionIsRestricted(this.action, this.selectedRowsData);
            },
        },

        data() {
            return {
                loading: false,
            };
        },

        watch: {
            disabled() {
                if (!this.disabled) {
                    this.loading = false;
                }
            },
        },
    };
</script>
