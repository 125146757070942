export default {
    dataTablesState: {},
    activeFilters: [],
    fieldValueQuery: null,
    filters: [],
    readonlyIds: [],
    searchQuery: null,
    selectedIds: [],
    selectedRowsData: [],
    showFilters: false,
    showOnlyActive: true,
    showOnlyCurrent: true,
    uniqueIdentifier: null,
};
