// @vue/component
export default {
    methods: {
        /**
         * @returns {boolean}
         */
        canHandleShortcuts() {
            let parent = this.$parent;
            let isActive = true;

            while (parent) {
                if (parent.$options.name === 'frm-tabbed-content-tab' && parent.active === false) {
                    isActive = false;
                    break;
                }

                parent = parent.$parent;
            }

            return isActive;
        },
    },
};
