export default function elementIsInViewport(el) {
    if (typeof window.jQuery === 'function' && el instanceof window.jQuery) {
        el = el[0];
    }

    if (!el) {
        throw new Error('elementIsInViewport: Element not found');
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
