<template>
    <button
        :class="'button button--icon '+buttonClass"
        :title="$t('patient.physitrack.open_patient')"
        data-testid="open-patient-in-physitrack"
        type="button"
        @click.prevent="open"
    >
        <slot :loading="loading" />
    </button>
</template>

<script>

import HandlesErrorResponses from '../mixins/HandlesErrorResponses';
import axios from 'axios';
import DialogManager from '../services/DialogManager';
import CreatesHiddenInputs from '../mixins/CreatesHiddenInputs';

export default {
    mixins: [HandlesErrorResponses, CreatesHiddenInputs],

    props: {
        patientId: {
            type: String,
            required: true,
        },
        buttonClass: {
            type: String,
            default: 'button--secondary',
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        async open() {
            this.loading = true;

            let data;
            try {
                data = (await axios.get(`/patient/${this.patientId}/physitrack_sso_parameters`)).data;
            } catch (error) {
                this.loading = false;

                await this.handleErrorResponse(error);

                return;
            }

            try {
                const windowRef = window.open(undefined, `physitrack-${new Date().getTime()}-${Math.random()}`);

                const ssoForm = windowRef.document.createElement('form');
                ssoForm.method = 'POST';
                ssoForm.action = data.url;

                const input = this.createHiddenInput('data[token]', data.token, windowRef);
                ssoForm.appendChild(input);

                Object.entries(data.patient).forEach(([name, value]) =>
                    ssoForm.appendChild(this.createHiddenInput(`data[patient][${name}]`, value, windowRef)));

                windowRef.document.body.appendChild(ssoForm);
                ssoForm.submit();
                windowRef.focus();
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);

                await DialogManager.alert(this.$t('patient.physitrack.error'));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
