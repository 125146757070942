<script>
    import S from 'string';
    import OverviewTable from '../Overview/Table';

    export default {
        extends: OverviewTable,

        methods: {
            rowGroupStartRender(rows, group) {
                const totalAmount = rows.data().toArray().reduce((acc, row) => acc + row.amount, 0);
                const title = `<strong>${S(group).escapeHTML().toString()}</strong> | ${this.$tc(rows.data()[0].grouping_amount_translation_key, rows.count(), { number: rows.count() })} | ${this.$c(totalAmount)}`;

                return OverviewTable.methods.rowGroupStartRender.call(this, rows, title);
            },
        },
    };
</script>
