<template>
    <div v-if="config.buttons.length > 0" class="overview__actions" :class="{'overview__actions--sticky': sticky}">
        <div class="button-group">
            <template v-for="(action, index) in config.buttons">
                <template v-if="action.type === 'break'">
                    <div :key="index" class="button-group__break" />
                </template>
                <template v-else>
                    <frm-overview-actions-button :key="index" :action="action" :config="config" :disabled="disabled" :handle-action="handleAction" />
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import DefaultOverviewMixin from '../../mixins/Overview/Default';
    import OverviewActionsButton from './ActionsButton';

    export default {
        mixins: [DefaultOverviewMixin],

        components: {
            'frm-overview-actions-button': OverviewActionsButton,
        },

        props: {
            config: {
                type: Object,
                required: true,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            handleAction: {
                type: Function,
                required: true,
            },

            sticky: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
