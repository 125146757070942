/**
 * Get an item from an object using "dot" notation.
 *
 * @param {Object} target
 * @param {String} key
 * @param {*} [defaultValue]
 *
 * @returns {*}
 */
export default function dataGet(target, key, defaultValue) {
    return key.split('.').reduce(
        (acc, part) => {
            return acc && acc[part] ? acc[part] : defaultValue;
        },
        target
    );
}
