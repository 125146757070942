import OverviewTableColumnRenderer from './OverviewTableColumnRenderer';
import iconRenderer from './iconRenderer';
import i18n from '../i18n';

export default class OverviewTableColumnConfigBuilder {
    /**
     * @param {Array} columns
     * @param {{[checkable]: Boolean, [groupsCollapsable]: Boolean, [subTables]: Boolean, [sortable]: Boolean, [sortableColumn]: Array, [orderableColumns]: String, [grouped]: Boolean, [showContextMenuButton]: Boolean}} [options]
     *
     * @returns {Array}
     */
    static build(columns, options = {}) {
        const config = [];

        if (options.groupsCollapsable) {
            config.push(this.getSpacerColumn());
        }

        if (options.checkable) {
            config.push(this.getCheckableColumn());
        }

        if (options.subTables) {
            config.push(this.getSubTablesColumn());
        }

        columns.forEach((column) => {
            config.push(this.getDefaultColumn(column, options));
        });

        if (options.showContextMenuButton) {
            config.push(this.getContextMenuButtonColumn());
        }

        if (options.sortable) {
            config.push(this.getSortableColumn(options.sortableColumn));
        }

        return config;
    }

    /**
     * @returns {{}}
     */
    static getSpacerColumn() {
        return {
            data: null,
            searchable: false,
            orderable: false,
            className: 'overview__column overview__column--spacer',
            render(data, type, row, meta) {
                if (type === 'display') {
                    return '';
                }

                return data;
            },
        };
    }

    /**
     * @returns {{}}
     */
    static getCheckableColumn() {
        return {
            data: null,
            searchable: false,
            orderable: false,
            className: 'overview__column overview__column--checkbox',
            render(data, type, row, meta) {
                if (type === 'display') {
                    if (row.readonly === true) {
                        return '';
                    }

                    return `<div class="form-checkbox overview-checkbox">
                        <input id="overview-active-${data.type}-${data.id}" name="overview-active[]" type="checkbox" value="${data.id}" class="form-checkbox__input">
                        <label for="overview-active-${data.type}-${data.id}" class="form-checkbox__label form-checkbox__label--no-content"></label>
                    </div>`;
                }

                return data;
            },
        };
    }

    /**
     * @returns {{}}
     */
    static getSubTablesColumn() {
        return {
            data: null,
            searchable: false,
            orderable: false,
            className: 'overview__column overview__column--sub-table',
            render(data, type, row, meta) {
                if (type === 'display') {
                    const icon = iconRenderer('caret-right', 'button__icon');

                    return `<button type="button" class="button button--icon button--text js-overview-sub-table-button">${icon}</button>`;
                }

                return data;
            },
        };
    }

    /**
     * @param {{data: String, name: String, type: String, options: {}}} columnOptions
     * @param {{[sortable]: Boolean, [grouped]: Boolean, [serverSide]: Boolean, [orderableColumns]: Array}} options
     *
     * @returns {{}}
     */
    static getDefaultColumn(columnOptions, options = {}) {
        const column = {
            data: columnOptions.data,
            name: columnOptions.name,
            className: 'overview__column overview__column--' + columnOptions.type,
            render(data, type, row, meta) {
                if (type === 'display') {
                    return OverviewTableColumnRenderer.render(columnOptions.type, data, columnOptions.options);
                }

                // Sort and filter by label if available (i.e. for statuslabels)
                if (data?.label) {
                    return data.label;
                }

                // Sort and filter by icon if available
                if (data?.icon) {
                    return data.icon;
                }

                return data;
            },
        };

        if (options.sortable || options.grouped || options.serverSide) {
            column.orderable = false;
        }

        if (options.orderableColumns) {
            column.orderable = options.orderableColumns.includes(column.name);
        }

        // Handle special types
        if (columnOptions.type === 'active') {
            column.render = function (data, type, row, meta) {
                if (type === 'display') {
                    return OverviewTableColumnRenderer.render(columnOptions.type, data, columnOptions.options);
                } else if (type === 'sort') {
                    return data === true ? 0 : 1;
                }

                return data;
            };
        }

        if (columnOptions.type === 'date') {
            column.render = function (data, type, row, meta) {
                if (type === 'display') {
                    return OverviewTableColumnRenderer.render(columnOptions.type, data, columnOptions.options);
                } else if (type === 'filter') {
                    return data ? data.substring(0, 10) : null;
                }

                return data;
            };
        }

        if (columnOptions.type === 'time') {
            column.render = function (data, type, row, meta) {
                if (type === 'display') {
                    return OverviewTableColumnRenderer.render(columnOptions.type, data, columnOptions.options);
                } else if (type === 'filter') {
                    return data ? data.substring(11) : null;
                }

                return data;
            };
        }

        // Add extra parameters (i.e. width)
        Object.assign(column, columnOptions.options);

        return column;
    }

    /**
     * @returns {{}}
     */
    static getContextMenuButtonColumn() {
        return {
            data: null,
            name: null,
            searchable: false,
            orderable: false,
            className: 'overview__column overview__column--actions-button',
            render(data, type, row, meta) {
                if (type === 'display') {
                    const disabled = row.context_menu_items && row.context_menu_items.data && !row.context_menu_items.data.length;
                    const icon = iconRenderer('dots', 'button__icon');

                    return `<button type="button" ${disabled ? 'disabled' : ''} class="button button--icon button--secondary js-overview-context-menu-button">${icon}</button>`;
                }

                return data;
            },
        };
    }

    /**
     * @param {String} sortableColumn
     *
     * @returns {{}}
     */
    static getSortableColumn(sortableColumn) {
        return {
            data: sortableColumn,
            name: sortableColumn,
            searchable: false,
            orderable: false,
            className: 'overview__column overview__column--sorting',
            render(data, type, row, meta) {
                if (type === 'display') {
                    const icon = iconRenderer('arrow-up-down');
                    const hint = i18n.t('overview.sorting-disabled');

                    return `<div class="overview__column-hint hint--left" aria-label="${hint}">${icon}</div>`;
                }

                return data;
            },
        };
    }
}
