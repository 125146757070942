<template>
    <div
        v-if="config.showSearch === true || config.showActiveFilter === true || config.showCurrentFilter === true || filtersComputed.length > 0"
        :class="{'overview__filters--grid': config.toggleableFilters || config.gridFilters}"
        class="overview__filters"
    >
        <div v-if="config.toggleableFilters" class="overview__filter-toggle">
            <button class="button button--text" type="button" @click="toggleFilters()">
                <span class="button__icon-group">
                    <frm-icon name="filter" class="button__icon" />
                    <span>{{ toggleFiltersLabel }}</span>
                </span>
            </button>
        </div>

        <template v-if="showFiltersComputed">
            <div
                :class="{'overview__filter-container--grid': filtersComputed.length > 1 && (config.toggleableFilters || config.gridFilters)}"
                class="overview__filter-container"
            >
                <div v-for="(filter, index) in filtersComputed"
                     :key="index"
                     :class="{'overview__category-filter--field-value': filter.type === 'field_value'}"
                     class="overview__category-filter"
                >
                    <frm-input-select
                        v-if="filter.type === 'institute-select'"
                        :id="'frm_overview_filter_' + filter.attribute + '_' + uniqueIdentifier"
                        v-model="instituteId"
                        :disabled="filter.disabled"
                        :name="filter.attribute"
                        :options="instituteOptions"
                    />
                    <frm-input-date-picker
                        v-if="filter.type === 'date'"
                        :id="'frm_overview_filter_' + filter.attribute + '_' + uniqueIdentifier"
                        :disabled="filter.disabled"
                        :name="filter.attribute"
                        :placeholder="filter.label || null"
                        :value="activeFilterValues[filter.attribute] || null"
                        @change="setActiveFilterAction({attribute: filter.attribute, value: $event})"
                    />
                    <frm-input-date-range-picker
                        v-if="filter.type === 'daterange'"
                        :id="'frm_overview_filter_' + filter.attribute + '_' + uniqueIdentifier"
                        :disabled="filter.disabled"
                        :name="filter.attribute"
                        :value="activeFilterValues[filter.attribute] || null"
                        @input="setActiveFilterAction({attribute: filter.attribute, value: $event})"
                    />
                    <FieldValueFilter
                        v-if="filter.type === 'field_value'"
                        :id="'frm_overview_filter_field_query_' + uniqueIdentifier"
                        v-model="fieldValueQuery"
                        :disabled="filter.disabled"
                        :options="filter.options"
                    />
                    <frm-input-select
                        v-if="filter.type === 'select'"
                        :id="'frm_overview_filter_' + filter.attribute + '_' + uniqueIdentifier"
                        :disabled="filter.disabled"
                        :name="filter.attribute"
                        :options="filter.options"
                        :value="activeFilterValues[filter.attribute] || null"
                        @change="setActiveFilterAction({attribute: filter.attribute, value: $event})"
                    />
                    <frm-input-smart-data-select
                        v-if="filter.type === 'smart_data_select'"
                        :id="'frm_overview_filter_' + filter.attribute + '_' + uniqueIdentifier"
                        :disabled="filter.disabled"
                        :name="filter.attribute"
                        :placeholder="filter.placeholder"
                        :data="filter.options.data || filter.options"
                        :multiple="true"
                        :value="activeFilterValues[filter.attribute] || filter.options.current || null"
                        @change="setActiveFilterAction({attribute: filter.attribute, value: $event})"
                    />
                    <div v-if="filter.type === 'checkbox'" class="form-checkbox">
                        <input :id="'frm_overview_filter_' + filter.attribute + '_' + uniqueIdentifier"
                               :checked="activeFilterValues[filter.attribute] || false"
                               class="form-checkbox__input"
                               type="checkbox"
                               value="1"
                               @input="setActiveFilterAction({attribute: filter.attribute, value: $event.target.checked})"
                        >
                        <label :for="'frm_overview_filter_' + filter.attribute + '_' + uniqueIdentifier"
                               class="form-checkbox__label"
                        >{{ filter.label }}</label>
                    </div>
                </div>
            </div>
        </template>

        <div v-if="config.showActiveFilter === true" class="overview__active-filter">
            <div class="form-checkbox form-checkbox--inline">
                <input
                    :id="'frm_overview_filter_active_' + uniqueIdentifier"
                    v-model="showOnlyActive"
                    class="form-checkbox__input"
                    type="checkbox"
                >
                <label :for="'frm_overview_filter_active_' + uniqueIdentifier"
                       class="form-checkbox__label"
                >{{ config.showOnlyActiveLabel }}</label>
            </div>
        </div>

        <div v-if="config.showCurrentFilter === true" class="overview__current-filter">
            <div class="form-checkbox form-checkbox--inline">
                <input
                    :id="'frm_overview_filter_current_' + uniqueIdentifier"
                    v-model="showOnlyCurrent"
                    class="form-checkbox__input"
                    type="checkbox"
                >
                <label :for="'frm_overview_filter_current_' + uniqueIdentifier"
                       class="form-checkbox__label"
                >{{ $t('overview.filters.current') }}</label>
            </div>
        </div>

        <div v-if="config.showSearch === true" class="overview__search-filter">
            <div class="form-icon-field">
                <input
                    :id="'frm_overview_filter_search_' + uniqueIdentifier"
                    v-model="searchQuery"
                    :placeholder="$t('overview.search')"
                    class="form-input form-icon-field__field"
                >
                <button type="button" class="form-icon-field__button" @click="searchQuery = ''">
                    <frm-icon :name="searchQuery ? 'cross-2' : 'magnifying-glass'" class="form-icon-field__icon" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapMutations, mapState } from '../../store/helpers';
import DefaultOverviewMixin from '../../mixins/Overview/Default';
import dateFormatter from '../../i18n/dateFormatter';
import debounce from '../../services/debounce';
import FieldValueFilter from './FieldValueFilter';
import HandlesInstituteFilter from '../../mixins/Overview/HandlesInstituteFilter';

export default {
    mixins: [DefaultOverviewMixin, HandlesInstituteFilter],

    components: {
        FieldValueFilter,
    },

    props: {
        config: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState([
            'filters',
            'showFilters',
            'uniqueIdentifier',
        ]),

        ...mapGetters([
            'activeFilterValues',
        ]),

        showOnlyActive: {
            get() {
                return this.getFromState('showOnlyActive');
            },
            set(value) {
                this.setShowOnlyActive(value);
            },
        },

        showOnlyCurrent: {
            get() {
                return this.getFromState('showOnlyCurrent');
            },
            set(value) {
                this.setShowOnlyCurrent(value);
            },
        },

        searchQuery: {
            get() {
                return this.getFromState('searchQuery');
            },
            set: debounce(
                function (value) {
                    this.setSearchQuery(value);
                },
                250
            ),
        },

        fieldValueQuery: {
            get() {
                return this.getFromState('fieldValueQuery');
            },
            set: debounce(
                function (value) {
                    this.setFieldValueQuery(value);
                },
                250
            ),
        },

        showFiltersComputed() {
            if (!this.config.toggleableFilters) {
                return true;
            }

            return this.showFilters;
        },

        filtersComputed() {
            return this.filters
                .filter(filter => filter.type !== 'hidden')
                .filter(filter => filter.type !== 'institute-select' || this.instituteOptions.length > 1)
                .map(filter => {
                    if (filter.type !== 'select') {
                        return filter;
                    }

                    const options = filter.options.filter(option => {
                        if (!option.dependent) {
                            return true;
                        }

                        return option.dependent_values.includes(this.activeFilterValues[option.dependent_attribute]);
                    });

                    return {
                        ...filter,
                        options,
                        disabled: options.length < 2, // There is always a placeholder so we must have at least two items.
                    };
                });
        },

        toggleFiltersLabel() {
            if (this.showFilters) {
                return this.$t('overview.filters.toggle-hide-label');
            }

            return this.$t('overview.filters.toggle-show-label');
        },
    },

    watch: {
        filtersComputed(filters) {
            filters.forEach(filter => {
                if (filter.disabled && Boolean(this.activeFilterValues[filter.attribute])) {
                    this.setActiveFilterAction({ attribute: filter.attribute, value: '' });
                }
            });
        },

        fieldValueQuery(fieldValueQuery) {
            if (fieldValueQuery && fieldValueQuery.field) {
                this.searchQuery = '';
            }
        },
    },

    mounted() {
        // Prefill date filters with the current date if empty
        this.config.filters.filter(filter => filter.type === 'date').forEach(filter => {
            if (!this.activeFilterValues[filter.attribute]) {
                this.setActiveFilterAction({
                    attribute: filter.attribute,
                    value: dateFormatter(moment().startOf('day')),
                });
            }
        });

        // Prefill daterange filters with the past thirty days if empty
        this.config.filters.filter(filter => filter.type === 'daterange').forEach(filter => {
            if (!this.activeFilterValues[filter.attribute]) {
                const now = new Date();

                this.setActiveFilterAction({
                    attribute: filter.attribute,
                    value: [new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30), now],
                });
            }
        });

        setTimeout(() => {
            // Trigger a resize so the endless scrollable datatable height is reset
            if (this.config.toggleableFilters && this.showFiltersComputed && this.config.endlessScrollable) {
                window.dispatchEvent(new Event('resize'));
            }
        }, 100);
    },

    methods: {
        ...mapMutations([
            'resetFieldValueQuery',
            'setActiveFilterAction',
            'setFieldValueQuery',
            'setSearchQuery',
            'setShowOnlyActive',
            'setShowOnlyCurrent',
            'toggleShowFilters',
        ]),

        toggleFilters() {
            this.toggleShowFilters();
            if (!this.showFilters) {
                this.resetFieldValueQuery();
            }

            // Trigger a resize so the endless scrollable datatable height is reset
            if (this.config.endlessScrollable) {
                window.dispatchEvent(new Event('resize'));
            }
        },
    },
};
</script>
