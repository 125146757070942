// @vue/component
export default {
    computed: {
        /**
         * @returns {[String]}
         */
        storeNamespace() {
            return ['overview', this.config.id];
        },
    },

    methods: {
        /**
         * @param {String} [key]
         *
         * @return {*|undefined}
         */
        getFromState(key) {
            return this.storeNamespace.concat(key ? [key] : []).reduce((state, namespace) => state ? state[namespace] : undefined, this.$store.state);
        },
    },
};
