<script>
    import $ from 'jquery';
    import S from 'string';
    import OverviewTable from '../Overview/Table';

    export default {
        extends: OverviewTable,

        methods: {
            rowGroupStartRender(rows, group) {
                const totalAmount = rows.data().toArray().reduce((acc, row) => acc + row.amount_incl_vat, 0);
                const totalAmountGranted = rows.data().toArray().reduce((acc, row) => acc + row.amount_granted, 0);
                const title = `<strong>${S(group).escapeHTML().toString()}</strong> | ${this.$tc('financial.values.number-of-appointments', rows.count(), { number: rows.count() })} | ${this.$c(totalAmount)} | ${this.$c(totalAmountGranted)}`;

                const rowData = rows.data()[0];
                const label = rowData.group_financial_state;

                const $group = $('<td class="overview__column overview__column--group"/>')
                    .attr('colspan', this.getGroupColspan() - 1)
                    .html(title)
                    .add(
                        $('<td class="overview__column overview__column--label"/>')
                            .append(label ? $(`<div class="label label--${label.style}"/>`).text(label.label) : null)
                    );

                return OverviewTable.methods.rowGroupStartRender.call(this, rows, $group);
            },
        },
    };
</script>
